import React from 'react'
import {
  faClone,
  faFileDownload,
  faPen,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { UncontrolledTooltip } from 'reactstrap'
import { useQueryClient } from '@tanstack/react-query'
import { useStore } from '~/dataStore'
import { getRequest } from '~/api/requests'
import { NotificationType, showNotification } from '~/utils/Notification'
import IconButton from '~/components/IconButton'
import useAbortController from '~/hooks/useAbortController'
import routes from '~/routes'
import { ServerStateKeysEnum } from '../useCampaigns'
import {
  getMainCampaignType,
  getAdditionalCampaignType
} from '~/pages/Campaign/Notification/Notification.service'
import { removeCampaign } from '~/api/campaigns'
import { deleteJourney } from '~/pages/Journeys/Connector/Journeys.connector'
import { ID } from '~/common.interface'

async function exportCampaign(url, email) {
  await getRequest(url)
    .then(() => {
      showNotification(
        `Your export has started. We'll email ${email} when it's completed.`,
        NotificationType.SUCCESS
      )
    })
    .catch(() => {
      showNotification(
        'Something went wrong exporting campaign',
        NotificationType.ERROR
      )
    })
}

interface IProps {
  [x: string]: any
}

export default function RowActions({ row }: IProps): React.ReactElement {
  const queryClient = useQueryClient()
  const {
    ui: { showModal },
    app: {
      currentAdmin: { email: currentAdminEmail, isDemoAdmin },
      currentApp: { id: appId },
      closeCurtain
    },
    campaign: { initExistingCampaign, inApp },
    goTo
  } = useStore()

  const abortController = useAbortController()

  const {
    id,
    status,
    actions: { exportData: exportUrl, delete: deleteUrl, duplicate, edit },
    typeList,
    disabled,
    isCampaign
  } = row

  function generateEditUrl() {
    if (status === 'Delivered') {
      return undefined
    }

    if (!isCampaign) {
      return routes.alertCampaignBuilder
    }

    return routes.campaignBuilder
  }

  async function duplicateCampaign() {
    try {
      closeCurtain()
      await initExistingCampaign(
        appId,
        id,
        true,
        false,
        abortController.getAbortController('duplicate').signal
      )
      goTo(
        'campaignBuilder',
        {
          appId,
          campaignType: getMainCampaignType(typeList),
          notificationType: getAdditionalCampaignType(typeList),
          size: inApp?.size
        },
        {}
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="row-actions">
      <IconButton
        id={`edit-${id}`}
        url={generateEditUrl()}
        params={{
          campaignId: id,
          campaignType: getMainCampaignType(typeList),
          notificationType: getAdditionalCampaignType(typeList),
          appId
        }}
        icon={faPen}
        dataTestId="editCampaignBtn"
        disabled={
          disabled ||
          edit === false ||
          status === 'Delivered' ||
          (isDemoAdmin && status !== 'Draft')
        }
      />
      <UncontrolledTooltip placement="bottom" target={`edit-${id}`}>
        Edit
      </UncontrolledTooltip>
      <IconButton
        icon={faClone}
        id={`duplicate-${id}`}
        disabled={!isCampaign || disabled || isDemoAdmin || duplicate === false}
        dataTestId="duplicateCampaignBtn"
        onClick={duplicateCampaign}
      />
      <UncontrolledTooltip placement="bottom" target={`duplicate-${id}`}>
        Duplicate
      </UncontrolledTooltip>
      <IconButton
        onClick={() =>
          exportCampaign(
            exportUrl
              ? `/api/v2/apps/${appId}/campaigns/${row.id}/export`
              : null,
            currentAdminEmail
          )
        }
        icon={faFileDownload}
        id={`export-${id}`}
        disabled={disabled || !exportUrl}
        dataTestId="exportCampaignBtn"
      />
      <UncontrolledTooltip placement="bottom" target={`export-${id}`}>
        Export
      </UncontrolledTooltip>
      <IconButton
        onClick={() => {
          showModal('deleteElementsModal', {
            elements: [row],
            action: (campaignId: ID) => {
              if (row.isCampaign) {
                removeCampaign(appId, campaignId)
              } else {
                deleteJourney(appId, campaignId)
              }
            },
            onSuccess: () => {
              queryClient.invalidateQueries([ServerStateKeysEnum.Campaigns])
            }
          })
        }}
        icon={faTrash}
        disabled={!deleteUrl || (isDemoAdmin && status !== 'Draft')}
        id={`delete-${id}`}
        dataTestId="deleteCampaignBtn"
      />
      <UncontrolledTooltip placement="bottom" target={`delete-${id}`}>
        Delete
      </UncontrolledTooltip>
    </div>
  )
}

import React from 'react'
import {
  faEyeSlash,
  faObjectUngroup,
  faObjectGroup,
  faPause,
  faPlay,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import reject from 'lodash/reject'
import { Button, Spinner } from 'reactstrap'
import pluralize from 'pluralize'
import {
  ICampaignsListItem,
  QUERY_PARAMS_DEFAULTS
} from '~/pages/Campaign/CampaignsList/CampaignsList.interface'
import { useStore, withStore } from '~/dataStore'
import CustomDropdown from '~/components/CustomDropdown'
import SearchInput from '~/components/forms/SearchInput'
import MultiSelect from '~/components/forms/MultiSelect'
import StatusOption from '~/components/Filters/StatusOption'
import GroupsOption from '~/components/Table/components/GroupsOption'
import { IGroup } from '~/dataStore/Groups/Groups.interface'
import { IGeneralBulkActions } from '~/common.interface'
import { useInvalidateCampaigns } from '../useCampaigns'
import { putRequest } from '~/api/requests'
import { showGeneralError } from '~/utils/validations'
import { NotificationType, showNotification } from '~/utils/Notification'
import { CampaignType } from '~/dataStore/Campaign/Campaign.interface'
import { MCampaignTypeToName } from '~/pages/Campaign/CampaignReports/Model/Report.map'

interface IProps {
  selectedCampaigns: Map<string, ICampaignsListItem>
  setSelectedCampaigns: (map: Map<string, ICampaignsListItem>) => void
  batchActionUrls: IGeneralBulkActions & { resume: string; pause: string }
  searchQuery: string
  handleSearch: (searchQuery: string) => void
  queryParams: typeof QUERY_PARAMS_DEFAULTS
  setSelectedGroup: (group: IGroup) => void
  isLoading: boolean
}

const Toolbar = ({
  batchActionUrls,
  searchQuery,
  handleSearch,
  queryParams,
  setSelectedGroup,
  selectedCampaigns,
  setSelectedCampaigns,
  isLoading
}: IProps) => {
  const {
    app: {
      appDetails: {
        featureFlags: { bannoPushSendToUrl }
      },
      currentAdmin
    },
    groups: { groups },
    ui: { showModal }
  } = useStore()
  const invalidateCampaigns = useInvalidateCampaigns()
  const clearSelectedAndInvalidate = () => {
    invalidateCampaigns()
    setSelectedCampaigns(new Map())
  }
  const successCallback = (actionPerformed: 'paused' | 'resumed') => {
    showNotification(
      `${pluralize('Campaign', selectedCampaigns.size)} ${actionPerformed}`,
      NotificationType.SUCCESS
    )
    clearSelectedAndInvalidate()
  }

  const batchActions = [
    {
      name: 'Add to a Group',
      icon: faObjectGroup,
      action: () => showModal('addElementsToGroup'),
      hidden: bannoPushSendToUrl
    },
    {
      name: 'Remove from a Group',
      icon: faObjectUngroup,
      action: () => showModal('removeElementsFromGroup'),
      hidden:
        Array.from(selectedCampaigns, ([key, value]) => value).flatMap(
          (c) => c.groups
        ).length === 0
    },
    {
      name: 'Resume',
      icon: faPlay,
      action: async () => {
        try {
          await putRequest(batchActionUrls.resume, {
            resourceIds: Array.from(selectedCampaigns, ([, value]) => value.id)
          })
          successCallback('resumed')
        } catch (err) {
          console.log(err)
          showGeneralError()
        }
      }
    },
    {
      name: 'Pause',
      icon: faPause,
      action: async () => {
        try {
          await putRequest(batchActionUrls.pause, {
            resourceIds: Array.from(selectedCampaigns, ([, value]) => value.id)
          })
          successCallback('paused')
        } catch {
          showGeneralError()
        }
      }
    },
    {
      name: 'Delete Campaigns',
      icon: faTrash,
      action: () =>
        showModal('deleteElementsModal', {
          elements: Array.from(selectedCampaigns, ([, value]) => value),
          url: batchActionUrls?.delete,
          onSuccess: clearSelectedAndInvalidate
        })
    },
    {
      name: 'Hide from feed',
      icon: faEyeSlash,
      action: () => {
        const campaignsToHide = Array.from(
          selectedCampaigns,
          ([, value]) => value
        ).filter((campaign) => campaign.hidable)
        showModal('hideFromFeedModal', {
          campaignsToHide,
          onSuccess: clearSelectedAndInvalidate
        })
      },
      hidden: !Array.from(selectedCampaigns, ([, value]) => value).some(
        (campaign: any) => campaign.hidable
      )
    }
  ]

  const handleFilterTypes = (values: string[]) => {
    queryParams.filterTypes = values.join(',')
  }

  const handleFilterTargets = (values: string[]) => {
    queryParams.filterTargets = values.join(',')
  }

  const handleFilterStatuses = (values: string[]) => {
    queryParams.filterStatuses = values.join(',')
  }

  const handleFilterGroups = (values: string[]) => {
    queryParams.groupIds = values.join(',')
  }

  const groupFilterOptions = groups.map((g) => ({
    name: (
      <GroupsOption
        {...g}
        setEditGroup={(id: string) => {
          setSelectedGroup(id)
          showModal('editGroupModal')
        }}
        setDeleteGroup={(id: string) => {
          setSelectedGroup(id)
          showModal('removeGroupModal')
        }}
      />
    ),
    value: g.id
  }))

  const batchActionsDropdown =
    bannoPushSendToUrl || currentAdmin.isDemoAdmin ? null : (
      <CustomDropdown
        options={batchActions}
        selectedItems={selectedCampaigns}
        visible={selectedCampaigns.size > 0}
        className="me-3"
      />
    )

  return (
    <div className="d-flex justify-content-between align-items-center p-3">
      <div className="d-flex">
        {batchActionsDropdown}
        <SearchInput
          placeholder="Search for a campaign"
          initialValue={searchQuery}
          onChange={handleSearch}
          className="me-3 flex-0-1"
        />

        <MultiSelect
          title="Type"
          selectedOptions={reject(
            queryParams.filterTypes?.split(','),
            (s) => s === ''
          )}
          onSelect={handleFilterTypes}
          resetOptionName="Show All"
          options={[
            {
              name: MCampaignTypeToName.get(CampaignType.PUSH),
              value: 'push_notification'
            },
            {
              name: MCampaignTypeToName.get(CampaignType.IN_APP),
              value: 'in_app_notification'
            },
            { name: MCampaignTypeToName.get(CampaignType.CARD), value: 'card' },
            {
              name: MCampaignTypeToName.get(CampaignType.SMS),
              value: 'sms_notification'
            },
            {
              name: MCampaignTypeToName.get(CampaignType.EMAIL),
              value: 'email_notification'
            }
          ]}
          className="me-3"
        />

        <MultiSelect
          title="Status"
          selectedOptions={reject(
            queryParams.filterStatuses?.split(','),
            (s) => s === ''
          )}
          onSelect={handleFilterStatuses}
          resetOptionName="Show All"
          options={[
            {
              name: <StatusOption name="Active" status="active" />,
              value: 'active'
            },
            {
              name: <StatusOption name="Draft" status="draft" />,
              value: 'drafts'
            },
            {
              name: <StatusOption name="Scheduled" status="scheduled" />,
              value: 'scheduled'
            },
            {
              name: <StatusOption name="Paused" status="paused" />,
              value: 'paused'
            },
            {
              name: <StatusOption name="Delivered" status="delivered" />,
              value: 'delivered'
            }
          ]}
          className="me-3"
        />

        <MultiSelect
          title="Groups"
          selectedOptions={reject(
            queryParams.groupIds?.split(','),
            (s) => s === ''
          )}
          onSelect={handleFilterGroups}
          resetOptionName="Show All"
          options={groupFilterOptions}
          className="me-3"
          bottomChildren={
            <div className="d-flex">
              <Button
                onClick={() => showModal('createGroupModal')}
                color="primary"
                data-testid="createGroupNameBtn"
                className="mx-3 my-2 w-100 text-nowrap">
                + Create Group Name
              </Button>
            </div>
          }
        />

        <MultiSelect
          title="Target"
          selectedOptions={reject(
            queryParams.filterTargets?.split(','),
            (s) => s === ''
          )}
          onSelect={handleFilterTargets}
          resetOptionName="Show All"
          options={[
            { name: 'Segment', value: 'segment' },
            { name: 'Geofence', value: 'geofence' },
            { name: 'Beacon', value: 'beacon' },
            { name: 'Events', value: 'event' }
          ]}
        />
      </div>
      <div className="d-flex">
        {isLoading && (
          <Spinner color="primary" type="grow" className="ms-auto" />
        )}
      </div>
    </div>
  )
}

export default withStore(Toolbar)

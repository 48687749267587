import { faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'reactstrap'
import { requestCampaignSendApproval } from '~/api/campaigns'
import { ID } from '~/common.interface'
import Modal from '~/components/modals/Modal/Modal'
import RequestNotificationSendingApproval from '~/components/modals/RequestNotificationSendingApproval'
import SendCampaignModal from '~/components/modals/SendCampaignModal'
import { useStore, withStore } from '~/dataStore'
import ExitCampaign from '~/pages/CampaignBuilder/Email/ExitCampaign'
import { sendJourney } from '~/pages/Journeys/Connector/Journeys.connector'
import { getChildByType } from '~/pages/Journeys/Journey.service'
import { Message } from '~/pages/Journeys/JourneyBuilder/Blocks'
import { JourneyBlockType } from '~/pages/Journeys/JourneyBuilder/Store/JourneyBuilder.interface'
import {
  clearNotifications,
  NotificationType,
  showNotification
} from '~/utils/Notification'
import useJourneyStore from '../NotificationBuilder.context'
import BuilderErrors from './BuilderErrors'
import EmailErrors from './EmailErrors'

interface IProps {
  className?: string
}

function BottomActions({ className }: IProps) {
  const {
    replaceTo,
    app: {
      currentAdmin: { isDemoAdmin }
    },
    goTo,
    ui: { showModal, unregisterAllModals },
    campaign: { forceShowTooltip, isCampaignSaving, setForceShowTooltip }
  } = useStore()
  const store = useJourneyStore()

  const isFinalStep =
    store.steps?.currentStep?.number === store.steps?.steps.length

  const { appId, campaignId } = useParams<{ appId: ID }>()

  const [isLoading, setIsLoading] = useState(false)

  const goToCampaignsList = () => {
    // emailBuilder.setHasChanged(false)
    clearNotifications(NotificationType.ERROR)
    unregisterAllModals()
    goTo('campaignList', { appId })
  }

  const showErrors = (error: Error & { body: any }) => {
    if (error.message === 'validation') {
      unregisterAllModals()
    } else {
      showNotification(
        <EmailErrors errors={error.body?.errors || error.body?.details} />,
        NotificationType.ERROR
      )
    }
  }

  async function saveNotification(): Promise<void> {
    if (!campaignId) return
    const messageBlock = getChildByType(JourneyBlockType.MESSAGE, store.entry)
    try {
      setIsLoading(true)
      await messageBlock.saveNotification(
        messageBlock.options[0].id,
        campaignId
      )
      await messageBlock.save(appId, campaignId)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  async function saveJourney(): Promise<void> {
    try {
      setIsLoading(true)
      const id = await store.save()
      replaceTo('alertCampaignBuilder', {
        appId,
        campaignId: id,
        mode: 'builder'
      })
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const saveAsDraft = async () => {
    setIsLoading(true)
    try {
      await saveJourney()
    } catch (error) {
      console.error(error)
      if (error.message !== 'validation') {
        showErrors(error)
        throw new Error()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleExit = async () => {
    await saveAsDraft()

    goToCampaignsList()
  }

  const onNextStep = async () => {
    store.resetError()
    store.entry.resetError()
    store.entry.resetChildrenErrors()

    if (isDemoAdmin && isFinalStep) {
      showModal(`requestNotificationSendingApproval`, {
        requestSend: async () => {
          try {
            await requestCampaignSendApproval(appId, {
              campaignId
            })
            showNotification(
              'Campaign successfully sent for approval.',
              NotificationType.SUCCESS
            )
            goToCampaignsList()
          } catch (error) {
            console.log(error)
          }
        }
      })
      return
    }

    if (store.steps?.currentStep?.number === 4) {
      store.validate(store.entry)

      if (!store.isValid) {
        setForceShowTooltip(true)
        return
      }
    }

    if (store.steps?.currentStep?.number === 1) {
      store.entry.validateName()
      if (!store.entry.name.isValid) {
        setForceShowTooltip(true)

        return
      }
    }

    if (store.steps?.currentStep?.store instanceof Message) {
      const { options } = store.steps?.currentStep?.store

      options[0].notification?.validateStep()

      if (!options[0].notification?.isStepValid) {
        setForceShowTooltip(true)
        return
      }

      try {
        await saveNotification()
      } catch (e) {
        console.log(e)
      }
    }

    setForceShowTooltip(false)
    if (isFinalStep) {
      showModal('sendCampaignModal')
    } else {
      await saveAsDraft()
      const nextStepNumber = (store.steps?.currentStep?.number || 1) + 1
      goTo(undefined, {}, { step: nextStepNumber })
    }
  }

  const getNextBtn = () => {
    if (isFinalStep) {
      if (isDemoAdmin) {
        return 'Request to Send'
      }
      return 'Send Campaign'
    }

    return 'Save & Continue'
  }

  const errors =
    [
      ...store.entry.errors,
      ...store.entry.children[0].options[0].notification?.getErrorMessages()
    ] || []

  return (
    <>
      <div className={cn('d-flex justify-content-end', className)}>
        <Button
          color=""
          className="bg-white px-4 btn--hover btn-outline-body"
          onClick={handleExit}>
          Exit
        </Button>
        <Button
          id="next-btn"
          color="body"
          disabled={isLoading}
          className={cn(
            { disabled: isLoading },
            'ms-2 px-4 d-flex align-items-center border- btn--hover'
          )}
          onClick={onNextStep}>
          {isCampaignSaving && (
            <FontAwesomeIcon className="me-2" icon={faSpinner} spin size="xs" />
          )}
          {getNextBtn()}
          <FontAwesomeIcon className="ms-2" icon={faChevronRight} />
        </Button>
        {errors.length > 0 && (
          <BuilderErrors
            key={`${errors.length}${forceShowTooltip}`}
            targetId="next-btn"
            messages={errors}
            close={() => {
              setForceShowTooltip(false)
            }}
            isOpen={forceShowTooltip}
          />
        )}
      </div>

      <Modal
        id="sendCampaignModal"
        size="md"
        className="send-campaign-modal"
        renderHeader={() => 'You’re about to activate this campaign'}
        render={(close: () => void) => (
          <SendCampaignModal
            startAt={store.entry.startAt.value}
            startNow={false}
            targeting={store.entry.targeting}
            onSubmit={async () => {
              try {
                await sendJourney(appId, campaignId)
                goToCampaignsList()
              } catch (e) {}
            }}
            showErrors={showErrors}
            onClose={close}
          />
        )}
      />

      <RequestNotificationSendingApproval notificationType="campaign" />
      <Modal
        id="exitCampaignBuilder"
        renderHeader={() => 'Exit Campaign Builder'}
        render={(close: () => void) => (
          <ExitCampaign onSubmit={goToCampaignsList} onClose={close} />
        )}
      />
    </>
  )
}

export default withStore(BottomActions)
